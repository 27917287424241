import "./App.css";
import React, { useRef, useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";

function App() {
  const [slideImages, setSlideImages] = useState([]);
  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    let images = [];
    for (let i = 1; i <= 33; i++) {
      images.push({
        url: `/company_intro/${i}.jpeg`,
        original: `/company_intro/${i}.jpeg`,
        thumbnail: `/company_intro/${i}.jpeg`,
      });
    }

    setSlideImages(images);
  }, []);

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    height: "100vh",
  };

  const images = [
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
  ];

  return (
    <ImageGallery
      infinite={true}
      items={slideImages}
      isFullscreen={true}
      /* autoPlay={true} */ showNav={false}
      showThumbnails={false}
      showFullscreenButton={false}
      showPlayButton={false}
      startIndex={startIndex}
      onClick={() =>
        setStartIndex((PreState) => {
          if (PreState >= slideImages.length - 1) return 1;
          return PreState + 1;
        })
      }
    />
  );
}

export default App;
